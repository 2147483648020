import React, { useState, Fragment } from 'react';

import { UploadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import styled from 'styled-components';

import axios from 'lib/axios.factory';

import { getAdsProviders } from '../../../../lib/adsProvider/google';
import { apiCall } from '../../../../lib/api';
import { getVideoThumbnails } from '../../../../lib/facebookPlateformApi';
import { MutedText } from '../../../common/MutedText';
import { SmallCompactPlaceholder } from '../../../common/Placeholders';

const Style = styled.div`
  button {
    display: flex;
    justify-content: center;
    gap: 1em;
    align-items: center;
  }
`;

const FileInput = ({ videoName, ...rest }) => {
  const id = Math.random() * 1000;

  return (
    <Fragment>
      <input
        {...rest}
        accept="video/3g2,video/3gp,video/3gpp,video/asf,video/avi,video/dat,video/divx,video/dv,video/f4v,video/flv
,video/gif,video/m2ts,video/m4v,video/mkv,video/mod,video/mov,video/mp4,video/mpe,video/mpeg,video/mpeg4,video/mpg,video/mts
,video/nsv,video/ogm,video/ogv,video/qt,video/tod,video/ts,video/vob,video/wmv"
        name="file"
        id={id}
        style={{ display: 'none' }}
        type="file"
      />
      <Button block icon={<UploadOutlined />}>
        <label htmlFor={id}>{videoName || 'Upload a video'}</label>
      </Button>
      <MutedText>
        <span style={{ fontSize: 9 }}>
          <b>Design recommendations</b>
          <br />
          File type: MP4, MOV or GIF <br />
          Ratio: 1:1 (for desktop or mobile) or 4:5 (for mobile only) <br />
          Video settings: H.264 compression, square pixels, fixed frame rate, progressive scan and stereo AAC audio
          compression at 128 kbps+
          <br />
          Resolution: At least 1080 x 1080 pixels <br />
          Video captions: Optional, but recommended
          <br />
          Video sound: Optional, but recommended
          <br />
          Videos should not contain edit lists or special boxes in file containers.
          <br />
          <b>Technical requirements</b>
          Video duration: 1 second to 241 minutes
          <br />
          Maximum file size: 4 GB
          <br />
          Minimum width: 120 pixels
          <br />
          Minimum height: 120 pixels
          <br />
        </span>
      </MutedText>
    </Fragment>
  );
};

export function FacebookVideoUpload(props: {
  account: accountT,
  adsProvider: adsProviderT,
  setThumb: any,
  thumb: string,
}) {
  const adsProvider = getAdsProviders(props.account).find((p) => p.id === props.adsProvider.id);
  const [loading, setLoading] = useState(false);
  const [videoId, setVideoId] = useState(undefined);
  const [videoName, setVideoName] = useState(undefined);

  async function getThumbnails(videoId) {
    setTimeout(async () => {
      const thumbData = await getVideoThumbnails(localStorage, axios, props.adsProvider, videoId);

      if (thumbData.length === 0) {
        await getThumbnails(videoId);
      } else {
        props.setThumb(thumbData[0].uri);
        setLoading(false);
      }
    }, 5000);
  }
  async function uploadVideo(event) {
    setLoading(true);
    const file = event.target.files[0];
    const res = await apiCall({
      localStorage,
      axios,
      method: 'POST',
      url: `https://graph.facebook.com/v22.0/${adsProvider.externalAccountId}/advideos`,
      params: {
        access_token: adsProvider.accessToken,
        upload_phase: 'start',
        file_size: file.size,
      },
    });

    setVideoName(file.name);
    setVideoId(res.videoId);
    props.onChange(res.videoId);
    await transferVideo(res.uploadSessionId, res.startOffset, res.endOffset, res.videoId, file, 1);
  }

  async function transferVideo(sessionId, start, end, videoId, file, number) {
    if (start === end) {
      await postVideo(sessionId, file.name, videoId);

      return;
    }

    const blob = new Blob([file], {
      type: file.type,
    });
    const chunk = blob.slice(start, end, file.type);
    const formData = new FormData();

    formData.append('video_file_chunk', chunk, `@chunk${number}.${file.type.split('/')[1]}`);
    const res = await uploadVideoChunk(start, sessionId, formData);

    await transferVideo(sessionId, res.start_offset, res.end_offset, videoId, file, number++);
  }

  async function uploadVideoChunk(start_offset, upload_session_id, formData) {
    const res = await axios.post(
      `https://graph.facebook.com/v22.0/${adsProvider.externalAccountId}/advideos`,
      formData,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
        params: {
          access_token: adsProvider.accessToken,
          upload_phase: 'transfer',
          start_offset,
          upload_session_id,
        },
      },
    );

    return res.data;
  }

  async function postVideo(upload_session_id, title, videoId) {
    const res = await apiCall({
      localStorage,
      axios,
      method: 'POST',
      url: `https://graph.facebook.com/v22.0/${adsProvider.externalAccountId}/advideos`,
      params: {
        access_token: adsProvider.accessToken,
        upload_phase: 'finish',
        upload_session_id,
        title,
      },
    });

    await getThumbnails(videoId);

    return res;
  }

  return (
    <Style>
      {loading && <SmallCompactPlaceholder />}
      {!loading && <FileInput videoName={videoName} onChange={uploadVideo} />}
    </Style>
  );
}
