import type { axiosParams } from './api';
import { apiCall, apiCallNoErrorHandler } from './api';

function verifyProvider(adsProvider: adsProviderT) {
  if (!adsProvider || adsProvider.providerType !== 'FACEBOOK') {
    throw new Error('Unable to use provider as Facebook provider', adsProvider);
  }
}

/**
 * Get facebook estimated read
 * @param localStorage
 * @param axios
 * @param adsProvider
 * @param targeting
 * @returns {Promise<*>}
 */
export async function getFacebookEstimated(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  adsProvider: adsProviderT,
  targeting: any,
): Promise<{ products: adsProducts }> {
  verifyProvider(adsProvider);
  const params = { accountId: adsProvider.accountId };
  const res = await apiCallNoErrorHandler({
    localStorage,
    axios,
    secure: true,
    method: 'post',
    url: `/ads-providers/facebook/${adsProvider.id}/estimated-delivered?`,
    params,
    data: { targeting },
  });

  return res.data;
}

/**
 * Get ad preview image
 * @param localStorage
 * @param axios
 * @param adsProvider
 * @param adFormat
 * @param creativeId
 * @returns {Promise<*>}
 */
export async function getFacebookAdPreview(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  adsProvider: adsProviderT,
  adFormat: string,
  creativeId: string,
): Promise<{ products: adsProducts }> {
  verifyProvider(adsProvider);
  const params = {};

  params.ad_format = adFormat;
  params.access_token = adsProvider.accessToken;
  // $FlowFixMe
  const res = await apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: `https://graph.facebook.com/v22.0/${creativeId}/previews/?`,
    params,
  });

  return res.data;
}

/**
 * Search for languages on Facebook
 * @param localStorage
 * @param axios
 * @param adsProvider
 * @param q
 * @returns {Promise<*>}
 */
export async function searchFacebookLocale(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  adsProvider: adsProviderT,
  q: string,
): Promise<{}> {
  verifyProvider(adsProvider);
  const params = {};

  params.type = 'adlocale';
  params.q = q;
  params.access_token = adsProvider.accessToken;
  // $FlowFixMe
  const res = await apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: `https://graph.facebook.com/v22.0/search?`,
    params,
  });

  return res.data;
}

/**
 * Retrieve Browse interest from FacebookAPI
 * @param localStorage
 * @param axios
 * @param adsProvider
 * @param q
 * @returns {Promise<*>}
 */
export async function browseFacebookTargeting(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  adsProvider: adsProviderT,
  q: string,
): Promise<{ products: adsProducts }> {
  verifyProvider(adsProvider);
  const params = {};

  params.access_token = adsProvider.accessToken;
  // $FlowFixMe
  const res = await apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: `https://graph.facebook.com/v22.0/${adsProvider.externalAccountId}/targetingbrowse?`,
    params,
  });

  return res.data;
}

/**
 * Search value on Facebook
 * @param localStorage
 * @param axios
 * @param adsProvider
 * @param q
 * @returns {Promise<*>}
 */
export async function searchFacebookTargeting(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  adsProvider: adsProviderT,
  q: string,
): Promise<{ products: adsProducts }> {
  verifyProvider(adsProvider);
  const params = {};

  params.q = q;
  params.access_token = adsProvider.accessToken;
  const res = await apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: `https://graph.facebook.com/v22.0/${adsProvider.externalAccountId}/targetingsearch?`,
    params,
  });

  return res.data;
}

/**
 * Search location on Facebook
 * @param localStorage
 * @param axios
 * @param adsProvider
 * @param q
 * @returns {Promise<*>}
 */
export async function searchFacebookLocation(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  adsProvider: adsProviderT,
  q: string,
): Promise<{ products: adsProducts }> {
  verifyProvider(adsProvider);
  const params = {};

  params.q = q || '';
  params.type = 'adgeolocation';
  params.limit = 1000;
  params.access_token = adsProvider.accessToken;
  params.locationTypes = q ? ['country', 'region', 'city'] : ['country'];
  const res = await apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: `https://graph.facebook.com/v22.0/search?`,
    params,
  });

  return res.data;
}

/**
 * Get Instagram AdAccount related to current user
 * @param localStorage
 * @param axios
 * @param facebookUser
 * @returns {Promise<*>}
 */
export async function getInstagramAccounts(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  accessToken: string,
  facebookPageId: string,
): Promise<{}> {
  const params = {};

  params.fields = 'id,username,profile_pic';
  params.access_token = accessToken;
  const res = await apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: `https://graph.facebook.com/v22.0/${facebookPageId}/instagram_accounts?`,
    params,
  });

  return res.data;
}

/**
 * Get Facebook AdAccount related to current user
 * @param localStorage
 * @param axios
 * @param facebookUser
 * @returns {Promise<*>}
 */
export async function getFacebookAccounts(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  facebookUser: any,
): Promise<{}> {
  const params = {};

  params.fields = 'name';
  params.limit = 1000;
  params.access_token = facebookUser.accessToken;
  const res = await apiCallNoErrorHandler({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: `https://graph.facebook.com/v22.0/${facebookUser.id}/adaccounts?`,
    params,
  });

  return res.data;
}

/**
 * Get Facebook pages link ot current user
 * @param localStorage
 * @param axios
 * @param facebookUser
 * @returns {Promise<*>}
 */
export async function getFacebookPages(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  facebookUser: { id: string, accessToken: string },
): Promise {
  const params = {};

  params.limit = 1000;
  params.access_token = facebookUser.accessToken;
  const res = await apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: `https://graph.facebook.com/v22.0/${facebookUser.id}/accounts?`,
    params,
  });

  return res.data;
}

/**
 * Return all Facebook Images
 * @param localStorage
 * @param axios
 * @param adsProvider
 * @param q
 * @returns {Promise<*>}
 */
export async function getFacebookImages(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  adsProvider: adsProviderT,
): Promise<{ products: adsProducts }> {
  verifyProvider(adsProvider);
  const params = {};

  params.access_token = adsProvider.accessToken;
  params.fields = 'url_128,url,hash';
  params.limit = 1000;
  const res = await apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: `https://graph.facebook.com/v22.0/${adsProvider.externalAccountId}/adimages?`,
    params,
  });

  return res.data;
}

export async function getVideoThumbnails(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  adsProvider: adsProviderT,
  videoId: string,
): Promise<> {
  verifyProvider(adsProvider);
  const params = {};

  params.access_token = adsProvider.accessToken;
  const res = await apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: `https://graph.facebook.com/v22.0/${videoId}/thumbnails?`,
    params,
  });

  return res.data;
}

export async function saveFacebookCreative(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  adsProvider: adsProviderT,
  creative: any,
  productUrl: string,
): Promise {
  verifyProvider(adsProvider);
  const params = { accountId: adsProvider.accountId };
  const res = await apiCallNoErrorHandler({
    localStorage,
    axios,
    secure: true,
    method: 'post',
    url: `/ads-providers/facebook/${adsProvider.id}/creative?`,
    params,
    data: { creative, url: productUrl },
  });

  return res.creative;
}

export async function convToFacebookSubType(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  adsProvider: adsProviderT,
  facebookCampaign: any,
  type: 'single' | 'collection' | 'carousel',
  media: 'photo' | 'video',
): Promise {
  verifyProvider(adsProvider);
  const params = { accountId: adsProvider.accountId };
  const res = await apiCallNoErrorHandler({
    localStorage,
    axios,
    secure: true,
    method: 'post',
    url: `/ads-providers/facebook/${adsProvider.id}/campaign-to-${type}-${media}?`,
    params,
    data: { facebookCampaign },
  });

  return res.facebookCampaign;
}
