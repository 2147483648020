import { Fragment, useContext } from 'react';

import { InfoCircleOutlined, PauseCircleTwoTone, PlayCircleTwoTone } from '@ant-design/icons';
import { Button, Card, Divider, notification, Statistic, Table, Tooltip } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { CheckboxMultiSelect } from 'components/common/antd/CheckboxMultiSelect';
import { MutedText } from 'components/common/MutedText';
import { CAMPAIGNS_TABLE_COLUMN_NAMES, DATE_FORMAT } from 'consts/consts';
import { DashboardFilterContext } from 'context/DashboardFilterProvider';
import { CAMPAIGN_AUTOMATIONS, FACEBOOK_ADS_UPGRADE, GOOGLE_RMF, useFeatureFlag } from 'lib/configcat';
import { useLazyGetAdGroupsByCampaignIdQuery } from 'stores/adGroupsSlice';
import { usePauseCampaignMutation, useRunCampaignMutation } from 'stores/campaignsSlice';
import { getCurrency } from 'utils/currency';

import type { accountT } from '../../../../../../flow-typed/pixelme/types';
import {
  ACOSValue,
  CurrencyValue,
  NumberVal,
  ROASValue,
} from '../../../AudienceBuilder/Campaigns/campaigns/RedirectsListLine';
import { AdsProviderLogo } from '../../common/AdsProviderLogo';
import { AttributionSteps } from '../../common/AttributionSteps';
import { FloatingRowAction } from '../../common/FloatingRowAction';
import { HideIfNoAmazonData } from '../../common/HideIfNoAmazonData';
import { NAValue } from '../../common/NAValue';
import { RowCellWithSpanOverflow } from '../../common/RowCellWithSpanOverflow';
import { ADS_PROVIDERS } from '../../constants';
import SearchTermsCampaignView from '../../GoogleRMF/SearchTermsCampaignView';
import { NewCampaignButton } from '../../NewCampaign/NewCampaignButton';
import { BrbValue } from '../BrbValue';
import { AutomationsButton } from './CampaignAutomations/AutomationsButton';
import { CloneCampaign } from './CloneCampaign';
import { CopyCampaignLink } from './CopyCampaignLink';
import { DebugCampaign } from './DebugCampaign';
import { DeleteCampaign } from './DeleteCampaign';
import { EditCampaign } from './EditCampaign';
import { CampaignAlertButton } from './InfoCampaign/CampaignAlertButton';
import { InfoCampaignButton } from './InfoCampaign/InfoCampaignButton';
import { Actions } from 'actions';

const Style = styled.div`
  overflow: auto;
  max-width: 100%;
  background-color: white;

  .ant-table-pagination.ant-pagination {
    justify-content: left;
    margin-left: 1em;
  }

  .ant-table-thead > tr > th {
    text-align: center;
  }

  .ant-table-column-title {
    font-size: 13px;
  }
`;

const Value = styled.div`
  display: inline-block;
  max-width: 200px;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #595959 !important;
  white-space: nowrap;
  wrap: none;
  text-overflow: ellipsis;
  overflow: hidden;

  .ant-table-thead > tr > th {
    text-align: center;
  }
`;

const EmptyText = styled.p`
  font-size: 1rem;
  margin: 1rem;
`;

const AlertContainer = styled.div`
  display: flex;
  column-gap: 0.25rem;
  align-items: center;
`;

const ColumnSelectorContainer = styled.div`
  background-color: #fff;
  padding: 0.75rem 1rem;
`;

const ColumnOptionsFooter = styled.div`
  display: flex;
  columns: 2;
  column-gap: 36px;
  > * {
    flex: 1;
  }
  > button {
    font-weight: 600;
    padding: 0;
    margin: 0;
    border: 0;
    text-align: left;
  }
`;

const Spacer = styled.div``;

function ICampaignsTable(props: {
  campaigns: campaignT[],
  allCampaigns: campaignT[],
  account: accountT,
  setSelectedRowKeys: any,
  productProviderId: string,
  productASIN: string,
  productId: string,
}) {
  const history = useHistory();
  const { currencyCode, currencySymbol } = getCurrency(props.account);
  const [shouldDisplaySearchTerms] = useFeatureFlag(GOOGLE_RMF);
  const [isAutomationsEnabled] = useFeatureFlag(CAMPAIGN_AUTOMATIONS);
  const [facebookAdsUpgradeEnabled] = useFeatureFlag(FACEBOOK_ADS_UPGRADE);
  const [getAdGroupsByCampaignId] = useLazyGetAdGroupsByCampaignIdQuery();
  const [runCampaign] = useRunCampaignMutation();
  const [pauseCampaign] = usePauseCampaignMutation();
  const { messages } = useIntl();

  const { ctx, updateCtx } = useContext(DashboardFilterContext);
  const [from, to] = ctx.adsReportsRange || [];

  const allowAdGroups = (campaign) =>
    [ADS_PROVIDERS.GOOGLE, ADS_PROVIDERS.TIKTOK].includes(campaign.providerType) ||
    (facebookAdsUpgradeEnabled && campaign.providerType === ADS_PROVIDERS.FACEBOOK);

  const selectedColumns = [
    {
      title: messages.locales.columns.status,
      dataIndex: 'status',
      key: 'status',
      width: 160,
      sorter: (a, b) => a.status - b.status,
      render: (v, campaign) => (
        <div
          style={{ display: 'flex', justifyContent: 'space-between' }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          {v === -5 && <span style={{ color: '#FFEA00' }}>Waiting (import)</span>}
          {v === 1 && <span style={{ color: '#F8A51B' }}>Paused</span>}
          {v === 2 && <span style={{ color: 'red' }}>Deleted</span>}
          {v === 3 && <span style={{ color: 'red' }}>Rejected</span>}
          {(v === 4 || v === -10) && <span style={{ color: '#0F993E' }}>Active</span>}
          {v === -50 && <span style={{ color: 'red' }}>Import failed</span>}
          {v === -51 && <span style={{ color: '#F8A51B' }}>Import failed (retrying)</span>}
          {v === -101 && <span style={{ color: '#F8A51B' }}>Invalid Campaign (please verify URL)</span>}
          {v === 5 && <span style={{ color: '#0F993E' }}>Finished</span>}
          {campaign.status === 4 /* ACTIVE*/ && (
            <PauseCircleTwoTone
              style={{ fontSize: '16px' }}
              twoToneColor="#F8A51B"
              title="Pause Campaign"
              onClick={(e) => {
                pauseCampaign({ accountId: props.account.id, campaignId: campaign.id });
                e.stopPropagation();
                e.preventDefault();
              }}
            />
          )}
          {campaign.status === 1 /* PAUSED*/ && (
            <PlayCircleTwoTone
              style={{ fontSize: '16px' }}
              twoToneColor="#52c41a"
              title="Launch Campaign"
              onClick={(e) => {
                runCampaign({ accountId: props.account.id, campaignId: campaign.id });
                e.stopPropagation();
                e.preventDefault();
              }}
            />
          )}
        </div>
      ),
    },
    {
      title: messages.locales.columns.impressions,
      dataIndex: 'impressions',
      key: 'impressions',
      width: 120,
      render: (v, campaign) => {
        if (!campaign.lastClicks && campaign.status > 0) {
          return (
            <RowCellWithSpanOverflow>
              <AttributionSteps report={campaign} />
            </RowCellWithSpanOverflow>
          );
        }

        return campaign.status < 0 || campaign.providerType === 'GENERIC' ? <></> : <NumberVal clicks={v}></NumberVal>;
      },
      sorter: (a, b) => a.impressions - b.impressions,
    },
    {
      title: messages.locales.columns.clicks,
      dataIndex: 'clicks',
      key: 'clicks',
      width: 120,
      render: (v, campaign) => (
        <HideIfNoAmazonData report={campaign}>
          {campaign.status < 0 ? <NAValue /> : <NumberVal clicks={v}></NumberVal>}
        </HideIfNoAmazonData>
      ),
      sorter: (a, b) => a.clicks - b.clicks,
    },
    {
      title: 'Amazon Clickthroughs',
      dataIndex: 'amazonClickThroughs',
      key: 'amazonClickThroughs',
      hidden: true,
      width: 120,
      render: (v, campaign) => (
        <HideIfNoAmazonData report={campaign}>
          <Fragment>
            <NumberVal clicks={v}></NumberVal>
          </Fragment>
        </HideIfNoAmazonData>
      ),
      sorter: (a, b) => a.clicks - b.clicks,
    },
    {
      title: messages.locales.columns.adSpend,
      dataIndex: 'adSpent',
      key: 'adSpent',
      width: 140,
      sorter: (a, b) => a.adSpent - b.adSpent,
      render: (v, campaign) => (
        <HideIfNoAmazonData report={campaign}>
          {campaign.status > 0 ? <CurrencyValue amount={v} currency={currencySymbol}></CurrencyValue> : <NAValue />}
        </HideIfNoAmazonData>
      ),
    },
    {
      title: messages.locales.columns.cpc,
      dataIndex: 'averageCpc',
      key: 'averageCpc',
      width: 140,
      sorter: (a, b) => a.averageCpc - b.averageCpc,
      render: (v, campaign) => (
        <HideIfNoAmazonData report={campaign}>
          <Fragment>
            <CurrencyValue amount={v} currency={currencySymbol}></CurrencyValue>
          </Fragment>
        </HideIfNoAmazonData>
      ),
    },
    {
      title: messages.locales.columns.addToCart,
      dataIndex: 'amazonTotalAddToCart',
      key: 'amazonTotalAddToCart',
      className: 'amazon',
      width: 140,
      sorter: (a, b) => a.amazonTotalAddToCart - b.amazonTotalAddToCart,
      render: (v, campaign) => (
        <HideIfNoAmazonData report={campaign}>
          {campaign.status > 0 ? <NumberVal clicks={v}></NumberVal> : <NAValue />}
        </HideIfNoAmazonData>
      ),
    },
    {
      title: messages.locales.columns.purchases,
      dataIndex: 'amazonTotalPurchases',
      key: 'amazonTotalPurchases',
      className: 'amazon',
      width: 120,
      sorter: (a, b) => a.amazonTotalPurchases - b.amazonTotalPurchases,
      render: (v, campaign) => (
        <HideIfNoAmazonData report={campaign}>
          {campaign.status > 0 ? <NumberVal clicks={v}></NumberVal> : <NAValue />}
        </HideIfNoAmazonData>
      ),
    },
    {
      title: messages.locales.columns.revenue,
      dataIndex: 'amazonTotalSales',
      key: 'amazonTotalSales',
      className: 'amazon',
      width: 120,
      render: (v, campaign) => (
        <HideIfNoAmazonData report={campaign}>
          {campaign.status > 0 ? <CurrencyValue amount={v} currency={currencySymbol}></CurrencyValue> : <NAValue />}
        </HideIfNoAmazonData>
      ),
      sorter: (a, b) => a.amazonTotalSales - b.amazonTotalSales,
    },
    {
      title: messages.locales.columns.conversionRate,
      dataIndex: 'conversionRate',
      key: 'conversionRate',
      className: 'amazon',
      width: 120,
      render: (v, campaign) => (
        <HideIfNoAmazonData report={campaign}>
          {campaign.status > 0 ? <Statistic value={v * 100} precision={2} suffix="%" /> : <NAValue />}
        </HideIfNoAmazonData>
      ),
      sorter: (a, b) => a.conversionRate - b.conversionRate,
    },
    {
      title: messages.locales.columns.addToCartRate,
      dataIndex: 'addToCartRate',
      key: 'addToCartRate',
      className: 'amazon',
      width: 120,
      render: (v, campaign) => (
        <HideIfNoAmazonData report={campaign}>
          {campaign.status > 0 ? <Statistic value={v * 100} precision={2} suffix="%" /> : <NAValue />}
        </HideIfNoAmazonData>
      ),
      sorter: (a, b) => a.addToCartRate - b.addToCartRate,
    },
    {
      title: messages.locales.columns.clickThroughRate,
      dataIndex: 'clickThroughRate',
      key: 'clickThroughRate',
      className: 'amazon',
      width: 120,
      render: (v, campaign) => (
        <HideIfNoAmazonData report={campaign}>
          {campaign.status > 0 ? <Statistic value={v * 100} precision={2} suffix="%" /> : <NAValue />}
        </HideIfNoAmazonData>
      ),
      sorter: (a, b) => a.clickThroughRate - b.clickThroughRate,
    },
    {
      title: messages.locales.columns.newToBrandRevenue,
      dataIndex: 'amazonNewToBrandSales',
      key: 'amazonNewToBrandSales',
      className: 'amazon',
      width: 120,
      render: (v, campaign) => (
        <HideIfNoAmazonData report={campaign}>
          {campaign.status > 0 ? <CurrencyValue amount={v} currency={currencySymbol}></CurrencyValue> : <NAValue />}
        </HideIfNoAmazonData>
      ),
      sorter: (a, b) => a.amazonTotalNewToBrandSales - b.amazonTotalNewToBrandSales,
    },
    {
      title: messages.locales.columns.newToBrandPurchases,
      dataIndex: 'amazonNewToBrandPurchases',
      key: 'amazonNewToBrandPurchases',
      className: 'amazon',
      width: 120,
      sorter: (a, b) => a.amazonTotalNewToBrandPurchases - b.amazonTotalNewToBrandPurchases,
      render: (v, campaign) => (
        <HideIfNoAmazonData report={campaign}>
          {campaign.status > 0 ? <NumberVal clicks={v}></NumberVal> : <NAValue />}
        </HideIfNoAmazonData>
      ),
    },
    {
      title: messages.locales.columns.netMargin,
      dataIndex: 'netMargin',
      key: 'netMargin',
      width: 120,
      sorter: (a, b) => a.netMargin - b.netMargin,
      render: (v, campaign) => (
        <HideIfNoAmazonData report={campaign}>
          {campaign.status > 0 ? <CurrencyValue amount={v} currency={currencySymbol}></CurrencyValue> : <NAValue />}
        </HideIfNoAmazonData>
      ),
    },
    {
      title: messages.locales.columns.roas,
      dataIndex: 'roas',
      key: 'roas',
      width: 120,
      render: (v, campaign) => (
        <HideIfNoAmazonData report={campaign}>
          {campaign.status > 0 ? <ROASValue roas={v} /> : <NAValue />}
        </HideIfNoAmazonData>
      ),
      sorter: (a, b) => a.roas - b.roas,
    },
    {
      title: messages.locales.columns.acos,
      dataIndex: 'acos',
      key: 'acos',
      width: 120,
      render: (v, campaign) => (
        <HideIfNoAmazonData report={campaign}>
          {campaign.status > 0 ? <ACOSValue acos={v} /> : <NAValue />}
        </HideIfNoAmazonData>
      ),
      sorter: (a, b) => a.acos - b.acos,
    },
    {
      title: (
        <Tooltip placement="bottom" title="On average, Brands can earn a 10% bonus from their qualifying sales">
          <span>
            {messages.locales.columns.avgBrandReferralBonus} <InfoCircleOutlined />
          </span>
        </Tooltip>
      ),
      dataIndex: 'amazonTotalSales',
      key: 'amazonTotalSales',
      width: 160,
      render: (v, campaign) => (
        <HideIfNoAmazonData report={campaign}>
          {campaign.status > 0 ? (
            <Fragment>
              {campaign.amazonTotalSales > 0 && (
                <BrbValue
                  brbBonusAmountCalculated={campaign.brbBonusAmountCalculated}
                  brbBonusAmount={campaign.brbBonusAmount}
                  currencySymbol={currencySymbol}
                  currencyCode={currencyCode}
                  brbEnrolled={_.get(
                    props.account.productProviders.find((p) => p.id === props.productProviderId),
                    'brbEnrolled',
                  )}
                />
              )}
            </Fragment>
          ) : (
            <NAValue />
          )}
        </HideIfNoAmazonData>
      ),
      sorter: (a, b) => a.amazonTotalSales - b.amazonTotalSales,
    },
    {
      title: messages.locales.columns.location,
      dataIndex: 'googleCampaign',
      key: 'googleCampaign',
      width: 300,
      render: (v, campaign) => {
        if (campaign.providerType === 'GOOGLE') {
          return (
            <Value>
              {_.get(campaign, 'googleCampaign.geoCriteria', [])
                .map((c) => c.canonicalName)
                .join(', ')}
            </Value>
          );
        } else if (campaign.providerType === 'FACEBOOK') {
          return (
            <Value>
              {_.get(campaign, 'facebookCampaign.ad.adset.value.geoLocations', [])
                .map((c) => c.name)
                .join(', ')}
            </Value>
          );
        }
      },
    },
    {
      title: messages.locales.columns.keywords,
      dataIndex: 'googleCampaign',
      key: 'googleCampaign',
      width: 300,
      render: (v, campaign) => (
        <Value>
          {(_.get(campaign, 'googleCampaign.adGroup.keywords') || [])
            .map((c) => c.text)
            .join(', ')}
        </Value>
      ),
      sorter: (a, b) => (a.totalVariationsCount || 0) - (b.totalVariationsCount || 0),
    },
    {
      title: messages.locales.columns.createdAt,
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 120,
      render: (v) => v && moment(v).format(DATE_FORMAT),
    },
    {
      title: messages.locales.columns.providerStatus,
      dataIndex: 'providerStatus',
      key: 'providerStatus',
      width: 250,
    },
  ]
    .filter((c) => !c.hidden)
    .filter((c) => props.selectedColumns.includes(c.key));

  const columns = [
    {
      title: '',
      dataIndex: 'providerType',
      key: 'providerType',
      width: 55,
      render: (v) => (
        <div className="small-provider">
          <AdsProviderLogo providerType={v} />
        </div>
      ),
    },
    {
      title: messages.locales.columns.campaignName,
      dataIndex: 'name',
      key: 'name',
      width: 300,
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (v, campaign) => (
        <AlertContainer>
          {v}
          {campaign.invalidNameDetectedAt && <CampaignAlertButton campaign={campaign} />}
        </AlertContainer>
      ),
    },
    ...selectedColumns,
    {
      title: '',
      width: 1,
      fixed: 'right',
      render: (v, campaign: campaignT) => (
        <FloatingRowAction>
          {campaign.invalidNameDetectedAt ? (
            <CampaignAlertButton campaign={campaign} />
          ) : (
            <InfoCampaignButton campaign={campaign} />
          )}
          <Divider type="vertical" />
          {campaign.status !== 2 && (
            <Fragment>
              <EditCampaign account={props.account} campaign={campaign} />
              <Divider type="vertical" />
            </Fragment>
          )}
          {isAutomationsEnabled && campaign.providerType === 'GOOGLE' && campaign.status !== 2 && (
            <Fragment>
              <AutomationsButton account={props.account} campaign={campaign} />
              <Divider type="vertical" />
            </Fragment>
          )}
          {campaign.redirect && (
            <Fragment>
              <CopyCampaignLink account={props.account} campaign={campaign} />
              <Divider type="vertical" />
            </Fragment>
          )}
          {(props.impersonated || window.location.href.includes('https://localhost')) && (
            <>
              <DebugCampaign account={props.account} campaign={campaign} />
              <Divider type="vertical" />
            </>
          )}
          <CloneCampaign account={props.account} campaign={campaign} />
          <Divider type="vertical" />
          {shouldDisplaySearchTerms && (
            <>
              <SearchTermsCampaignView />
              <Divider type="vertical" />
            </>
          )}
          <DeleteCampaign account={props.account} campaign={campaign} />
        </FloatingRowAction>
      ),
    },
  ];

  const rowSelection = {
    selectionType: 'checkbox',
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      props.setSelectedRowKeys(selectedRowKeys);
    },
  };

  if (!props.allCampaigns.length) {
    return (
      <Card style={{ width: '100%', textAlign: 'center' }}>
        <EmptyText>There are no campaigns for this product</EmptyText>
        <NewCampaignButton account={props.account} productId={props.productId} />
      </Card>
    );
  } else if (!props.campaigns.length) {
    return (
      <Card style={{ width: '100%', textAlign: 'center' }}>
        <EmptyText>There is no data that matches the filters you have set</EmptyText>
      </Card>
    );
  }

  return (
    <Style>
      <ColumnSelectorContainer>
        <CheckboxMultiSelect
          options={Object.entries(CAMPAIGNS_TABLE_COLUMN_NAMES).map(([value, label]) => ({ value, label }))}
          value={props.selectedColumns}
          onChange={props.updateColumns}
          placeholder="Column Options"
          dontShowSelectAllOption
          dropdownMatchSelectWidth={false}
          columns={2}
          listHeight={500}
          dropdownStyle={{ width: 500 }}
          tagRender={() => 'Column Options'}
          footer={
            <ColumnOptionsFooter>
              <Spacer />
              <Button type="link" onClick={props.resetColumns}>
                Restore Default Table
              </Button>
            </ColumnOptionsFooter>
          }
        />
      </ColumnSelectorContainer>
      <Table
        style={{ cursor: 'pointer' }}
        rowKey="id"
        tableLayout="fixed"
        dataSource={props.campaigns}
        rowSelection={rowSelection}
        pagination={{
          defaultPageSize: 25,
        }}
        onRow={(campaign) => ({
          onClick: async () => {
            if (allowAdGroups(campaign)) {
              const { data } = await getAdGroupsByCampaignId(
                {
                  campaignId: campaign.id,
                  from: from && moment(from).toISOString(),
                  to: to && moment(to).toISOString(),
                },
                true,
              );

              if (!data.adgroups || data.adgroups.length === 0) {
                return notification.error({
                  message: 'No Ad Groups Found',
                  description: 'No Ad Groups Found for this campaign',
                });
              } else if (data.adgroups.length === 1 && data.adgroups[0].totalVariationsCount > 0) {
                // To skip Adgroups if only one is present
                history.push(
                  `/${props.account.id}/adsprovider/${props.productProviderId}/${props.productASIN}/${campaign.providerType}/campaigns/${campaign.id}/adgroups/${data.adgroups[0].id}/variations`,
                );
              } else {
                history.push(
                  `/${props.account.id}/adsprovider/${props.productProviderId}/${props.productASIN}/${campaign.providerType}/campaigns/${campaign.id}/adgroups`,
                );
              }
            }
          },
        })}
        rowClassName={(record) =>
          `${record.totalCampaignsCount === 0 || !record.lastClicks ? 'row-empty' : ''} ${
            record.outOfLimits ? 'row-out-of-limits' : ''
          } ${allowAdGroups(record) ? '' : 'default-cursor'}`
        }
        columns={columns}
      />
      <div style={{ textAlign: 'center' }}>
        <MutedText>{messages.locales.dash.infoAttribution}</MutedText>
      </div>
    </Style>
  );
}

const mapStateToProps = (state: any) => ({
  impersonated: state.user.impersonated,
  user: state.user.user,
  selectedColumns: state.campaignsTable.selectedColumns,
});

const mapDispatchToProps = function (dispatch: Dispatch<*>): {} {
  return {
    updateColumns: (columns: string[]) => dispatch(Actions.front.campaignsTable.columns.update.request(columns)),
    resetColumns: () => dispatch(Actions.front.campaignsTable.columns.reset.request()),
  };
};

export const CampaignsTable = connect(mapStateToProps, mapDispatchToProps)(ICampaignsTable);
